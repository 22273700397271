<template>
  <div class="col-12 m-0 p-0 boxweekly">
    <div class="row m-0 pt-3">
      <div class="col m-0 p-0">
        <div class="row m-0 p-0">
          <!-- ----------------- -->
          <div class="col-8">
            <div class="col-12 dropdown dropdown-click pointer">
              <div
                class="btn-link"
                href=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="row w-100">
                  <div class="col-6 m-0 p-0 text-center day">
                    {{ dateNow }}
                  </div>
                  <!-- ------------------------ -->
                  <div class="col-6 m-0 p-0 text-center weekmonth">
                    <p>{{ weekdaytxt }}</p>

                    <p>{{ monthtxt }}</p>
                  </div>
                </div>
              </div>
              <!-- ------------------------- -->
              <div class="dropdown-default dropdown-menu p-0" style="">
                <DatePicker
                  :model-config="modelConfig"
                  v-model="date"
                  :max-date="new Date()"
                />
              </div>
            </div>
          </div>
          <!-- -------------- today --------------- -->
          <div
            class="
              col-4
              pointer
              p-0
              m-0
              justify-content-center
              d-flex
              align-items-center
            "
          >
            <div class="m-0 py-2 px-5 boxtoday" @click="clickToday()">
              <p class="today p-0 m-0">Today</p>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------------- 7 day ---------------------------- -->
      <div class="col-7 p-0 m-0 text-center" :key="listWeek">
        <div class="row">
          <div
            class="
              col-1
              pointer
              p-0
              text-right
              d-flex
              align-items-center
              justify-content-end
            "
            @click="clickPrev()"
          >
            <i class="fas fa-angle-left"></i>
          </div>
          <div
            class="col pb-2 border-bottom pointer"
            v-for="(e, index) in listWeek"
            :key="index"
          >
            <div
              :class="`row h-100 m-0 p-0 ${
                e.select.data[0] == 1 ? 'weekly-picker' : ''
              } ${e.select.data[0] == -1 ? 'weekly-cantpicker' : ''}`"
              @click="pickday(e.dd, `${e.yyyy}-${e.mm}-${e.dd}`)"
            >
              <div
                :class="e.select.data[0] == 1 ? 'selectdayofweek' : 'dayofweek'"
                class="col-12 p-0 m-0"
              >
                <span class="">{{ e.weekday }} </span>
              </div>
              <div
                :class="
                  e.select.data[0] == 1 ? 'selectdayofweek' : 'dayfomonth'
                "
                class="col-12 p-0 m-0"
              >
                <span class="">{{ e.dd }} </span>
              </div>
            </div>
          </div>
          <div class="col-1 pointer p-0 text-left d-flex align-items-center">
            <div @click="clickNext()" v-if="!getNext">
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import * as serviceAPI from "@/services/API.service";
import * as serviceMain from "@/services/main.service";
import ShortUrlCard from "@/components/shorturl/ShortUrlCard.vue";
import ShortUrlCardDetail from "@/components/shorturl/ShortUrlCardDetail.vue";
import ShortUrlChart from "@/components/shorturl/ShortUrlChart.vue";
import ShortUrlAdd from "@/components/shorturl/ShortUrlAdd.vue";
import { Calendar, DatePicker } from "v-calendar";
import { useI18n } from "vue-i18n";
export default {
  name: "ShortUrl",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    ShortUrlCard,
    ShortUrlCardDetail,
    ShortUrlChart,
    ShortUrlAdd,
    Calendar,
    DatePicker,
  },
  data: function () {
    return {
      typeSearch: [
        {
          type: "All",
          status: true,
        },
        {
          type: "Often use",
          status: false,
        },
        {
          type: "Fevorite",
          status: false,
        },
      ],
      WeekDay: ["S", "M", "T", "W", "T", "F", "S"],
      dateNow: "",
      monthtxt: "",
      weekdaytxt: "",
      monthNow: "",
      yearNow: "",
      filterNow: "",
      txtSearch: "",
      listCard: "",
      listWeek: "",
      date: new Date(),
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      weekclick: "prev",
    };
  },
  mounted() {
    this.dateNow = this.getNDay;
    this.monthNow = this.getNMonth;
    this.yearNow = this.getNYear;
    this.filterNow = 0;
    this.date = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
    this.GetShortUrlByUser();
  },
  methods: {
    pickday(index, temp) {
      let today = new Date();
      today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
      if (temp > today) {
        return;
      }
      for (let i = 0; i < this.listWeek.length; i++) {
        if (this.listWeek[i].dd == index) {
          this.listWeek[i].select.data[0] = 1;
          this.dateNow = this.listWeek[i].dd;
          this.monthNow = this.listWeek[i].mm;
          this.yearNow = this.listWeek[i].yyyy;
          this.monthtxt = this.listWeek[i].monthtxt;
          this.weekdaytxt = this.listWeek[i].weekdaytxt;
          this.date = `${this.listWeek[i].yyyy}-${this.listWeek[i].mm}-${this.listWeek[i].dd}`;
        } else {
          this.listWeek[i].select.data[0] = 0;
          let today = new Date();
          for (let i = 0; i < this.listWeek.length; i++) {
            let tempdate = new Date();
            today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
            tempdate =
              this.listWeek[i].yyyy +
              "-" +
              this.listWeek[i].mm +
              "-" +
              this.listWeek[i].dd;
            if (tempdate > today) {
              this.listWeek[i].select.data[0] = -1;
            }
          }
        }
      }
    },
    picktype(index) {
      for (let i = 0; i < this.typeSearch.length; i++) {
        if (i == index) {
          this.typeSearch[i].status = true;
          this.filterNow = i;
        } else {
          this.typeSearch[i].status = false;
        }
      }
      this.GetShortUrlByUser();
    },
    async GetShortUrlByUser() {
      try {
        let data = {
          dt: this.date,
          filter: this.filterNow,
          txtSearch: this.txtSearch,
        };
        //console.log(data);
        this.$emit("get_date", data.dt);

        // let getAPI = await serviceAPI.call_API(
        //   "post",
        //   "shorturl/GetShortUrlByUser",
        //   data,
        //   "auth"
        // );

        // this.listCard = getAPI;
        // console.log("AddJobStageTemplates:")
        // console.log(getAPI);
        // $('#new-member').modal('hide');
        // this.$emit('addTemplate')
        // this.refreshForm()
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async GetCalendar(weekclick) {
      try {
        let data = {
          type: weekclick,
          date: this.date,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "shorturl/GetCalendar",
          data,
          "auth"
        );
        if (weekclick == "next") {
          this.listWeek = getAPI.data.data;
        } else {
          this.listWeek = getAPI.data.data.slice().reverse();
        }
        let today = new Date();
        for (let i = 0; i < this.listWeek.length; i++) {
          let tempdate = new Date();
          today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
          tempdate =
            this.listWeek[i].yyyy +
            "-" +
            this.listWeek[i].mm +
            "-" +
            this.listWeek[i].dd;
          if (tempdate > today) {
            this.listWeek[i].select.data[0] = -1;
          }
          if (this.listWeek[i].select.data[0] == 1) {
            this.dateNow = this.listWeek[i].dd;
            this.monthtxt = this.listWeek[i].monthtxt;
            this.weekdaytxt = this.listWeek[i].weekdaytxt;
          }
        }
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    changDate() {
      let have;
      for (let i = 0; i < this.listWeek.length; i++) {
        if (
          this.listWeek[i].dd ==
          this.date.substring(this.date.length - 2, this.date.length)
        ) {
          have = "have";
          this.listWeek[i].select.data[0] = 1;
          this.dateNow = this.listWeek[i].dd;
          this.monthtxt = this.listWeek[i].monthtxt;
          this.weekdaytxt = this.listWeek[i].weekdaytxt;
        } else {
          this.listWeek[i].select.data[0] = 0;
          let today = new Date();
          for (let i = 0; i < this.listWeek.length; i++) {
            let tempdate = new Date();
            today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
            tempdate =
              this.listWeek[i].yyyy +
              "-" +
              this.listWeek[i].mm +
              "-" +
              this.listWeek[i].dd;
            if (tempdate > today) {
              this.listWeek[i].select.data[0] = -1;
            }
          }
        }
      }
      if (have == "have") {
        console.log("have");
      } else {
        console.log("don't have");
        if (
          this.date.substring(this.date.length - 2, this.date.length) ==
          this.getNDay
        ) {
          this.GetCalendar("today");
        } else if (
          this.date.substring(this.date.length - 2, this.date.length) <
          this.getMinWeek
        ) {
          this.weekclick = "prev";
          this.GetCalendar("prev");
        } else if (
          this.date.substring(
            this.date.length - 2,
            this.date.length > this.getMaxWeek
          )
        ) {
          this.weekclick = "next";
          this.GetCalendar("next");
        }
      }
    },
    clickToday() {
      this.date = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
      this.dateNow = getNDay;
      this.GetCalendar("today");
    },
    clickPrev() {
      let pick;
      for (let i = 0; i < this.listWeek.length; i++) {
        if (
          this.listWeek[i].dd ==
          this.date.substring(this.date.length - 2, this.date.length)
        ) {
          pick = i - 1;
        }
      }
      console.log(pick);
      if (pick < 0) {
        this.weekclick = "prev";
        this.GetCalendar("prev");
      }
      for (let i = 0; i < this.listWeek.length; i++) {
        if (i == pick) {
          this.listWeek[i].select.data[0] = 1;
          this.date = `${this.listWeek[i].yyyy}-${this.listWeek[i].mm}-${this.listWeek[i].dd}`;
          this.dateNow = this.listWeek[i].dd;
          this.monthtxt = this.listWeek[i].monthtxt;
          this.weekdaytxt = this.listWeek[i].weekdaytxt;
        } else {
          this.listWeek[i].select.data[0] = 0;
          let today = new Date();
          for (let i = 0; i < this.listWeek.length; i++) {
            let tempdate = new Date();
            today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
            tempdate =
              this.listWeek[i].yyyy +
              "-" +
              this.listWeek[i].mm +
              "-" +
              this.listWeek[i].dd;
            if (tempdate > today) {
              this.listWeek[i].select.data[0] = -1;
            }
          }
        }
      }
    },
    clickNext() {
      let pick;
      for (let i = 0; i < this.listWeek.length; i++) {
        if (
          this.listWeek[i].dd ==
          this.date.substring(this.date.length - 2, this.date.length)
        ) {
          pick = i + 1;
        }
      }
      if (pick > 6) {
        this.weekclick = "next";
        this.GetCalendar("next");
      }
      for (let i = 0; i < this.listWeek.length; i++) {
        if (i == pick) {
          this.listWeek[i].select.data[0] = 1;
          this.date = `${this.listWeek[i].yyyy}-${this.listWeek[i].mm}-${this.listWeek[i].dd}`;
          this.dateNow = this.listWeek[i].dd;
          this.monthtxt = this.listWeek[i].monthtxt;
          this.weekdaytxt = this.listWeek[i].weekdaytxt;
        } else {
          this.listWeek[i].select.data[0] = 0;
          let today = new Date();
          for (let i = 0; i < this.listWeek.length; i++) {
            let tempdate = new Date();
            today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
            tempdate =
              this.listWeek[i].yyyy +
              "-" +
              this.listWeek[i].mm +
              "-" +
              this.listWeek[i].dd;
            if (tempdate > today) {
              this.listWeek[i].select.data[0] = -1;
            }
          }
        }
      }
    },
  },
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
    getNDay: function () {
      let today = new Date();
      let dd = today.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      return dd;
    },
    getNMonth: function () {
      let today = new Date();
      let mm = today.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      return mm;
    },
    getNYear: function () {
      let today = new Date();
      let yyyy = today.getFullYear();
      return yyyy;
    },
    getSelectDate: function () {
      return this.date;
    },
    getMinWeek: function () {
      if (this.listWeek != "") return this.listWeek[0].dd;
      return null;
    },
    getMaxWeek: function () {
      if (this.listWeek != "") return this.listWeek[6].dd;
      return null;
    },
    getdd: function () {
      if (this.listWeek != "") return this.listWeek[6].dd;
      return null;
    },
    getmonthtxt: function () {
      if (this.listWeek != "") return this.listWeek[6].dd;
      return null;
    },
    getweekdaytxt: function () {
      if (this.listWeek != "") return this.listWeek[6].dd;
      return null;
    },
    getNext: function () {
      let today = new Date();
      today = this.getNYear + "-" + this.getNMonth + "-" + this.getNDay;
      if (this.date >= today) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    getSelectDate: function () {
      // console.log(object);
      // if(this.date.substring(this.date.length-2,this.date.length) == this.getNDay){
      //   this.GetCalendar('today');
      // }
      // else{
      //   this.changDate();
      // }
      this.changDate();
      // this.GetCalendar(this.getSelectDate);
      this.GetShortUrlByUser();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mainhead {
  background: linear-gradient(88.39deg, #394352 0%, #90969e 109.01%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 12px 12px 0px;
  color: white;
}
.boxweekly {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
}

.weekly-cantpicker {
  background: #d7dbe1;
  // border-radius: 10px;
  color: white;
}
.bguntype {
  background: #f1f5f9;
  color: black;
  border-block-color: #f1f5f9;
}
.font-type {
  font-size: 0.8rem;
}
@media screen and (max-width: 768px) {
  .font-type {
    font-size: 0.6rem;
  }
}
.day {
  color: #212525;
  font-weight: 500;
  font-size: 2.4em;
}
.weekmonth {
  color: #bcc1cd;
  font-weight: 500;
  font-size: 0.78em;
}
.boxtoday {
  background: #d4ffec;
  border-radius: 8px;
}
.today {
  color: #4dc591;
  font-weight: 600;
  font-size: 0.88em;
}
.dayofweek {
  color: #bcc1cd;
  font-weight: 500;
  font-size: 0.66px;
}
.dayfomonth {
  color: #212525;
  font-weight: 600;
  font-size: 0.88px;
}
.selectdayofweek {
  color: #ffffff;
  font-weight: 500;
  font-size: 0.66px;
}
.selectdayfomonth {
  color: #ffffff;
  font-weight: 600;
  font-size: 0.88px;
}
.weekly-picker {
  background: #64748b;
  border-radius: 10px;
  // color: white;
}
</style>
