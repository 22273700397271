<template>
  <div class="m-0 p-0 bg">
    <div class="row m-0 pl-lg-5">
      <div class="m-0 pl-lg-5">
        <div class="m-0 pl-lg-3 pl-md-4">
          <div v-if="line == '1'" class="col-12 m-0 tital font-Roboto">
            {{ tital }}
          </div>
          <div v-if="line == '1.2'" class="col-12 mx-0 mt-1 titalline12">
            {{ tital }}
          </div>
          <div v-if="line == '1.2'" class="col-12 mx-0 mb-1 subtital12">
            {{ subtital }}
          </div>
          <div v-if="line == '2'" class="col-12 mx-0 mt-1 titalline2">
            {{ tital }}
          </div>
          <div v-if="line == '2'" class="col-12 mx-0 mb-1 subtital">
            {{ subtital }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftHeader",
  props: ["tital", "subtital", "line"],
};
</script>

<style scoped>
.bg {
  background: #394352;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 12px 12px 0px;
}
.tital {
  font-size: 4em;
  font-weight: 700;
  color: #ffffff;
}
.titalline12 {
  font-size: 2.67em;
  font-weight: 700;
  color: #ffffff;
}
.titalline2 {
  font-size: 2.25em;
  font-weight: 400;
  color: #ffffff;
}
.subtital {
  font-size: 1.5em;
  font-weight: 400;
  color: #ffffff;
}
.subtital12 {
  font-size: 1em;
  font-weight: 700;
  color: #ffffff;
}
</style>