<template>
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0">
      <p class="weekly">Weekly</p>
    </div>
    <div class="col-12 m-0 p-0">
      <div class="row m-0 p-0">
        <div class="col-12 col-md-5 m-0 px-2">
          <div class="card bordercard h-100">
            <p class="pl-3 pt-5 tital">DEVICES</p>

            <div class="row m-0 p-0">
              <div class="col-12">
                <!-- ----- chart ----------- -->
                <MyChart :data="itemdevice" />
                <!-- -------- chart -------------- -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-7 m-0 px-2">
          <div class="card bordercard h-100">
            <p class="pl-3 pt-5 tital">CITIES</p>
            <p class="mt-5 ml-5 text-danger">* ยังไม่มีข้อมูล</p>
            <!-- <div class="row m-0 p-0">
              <div class="col-6">
              
                <MyChart :data="data" />
               
              </div>
              <div class="col-6">
                
                <MyChart :data="data" />
               
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from "@/components/shorturl/MyChart.vue";
export default {
  name: "ShortUrlCardDetail",
  props: ["itemdevice"],
  components: { MyChart },
  data() {
    return {
      data: [
        // { v: 1451, bg: "#F0F8FF", name: "Decktop" },
        // { v: 1000, bg: "#F0FFFF", name: "Mobile Phone" },
        // { v: 900, bg: "#F0F8FF", name: "Tablet" },
        // { v: 850, bg: "#7FFF00", name: "Unknown" },
        // { v: 300, bg: "#F0F8FF", name: "Ebook Reader" },
        // { v: 352, bg: "#F0FFFF", name: "Nodevice data" },
      ],
    };
  },

  mounted() {
    
  },
  methods: {
   
  },
  computed: {},
  watch: {},
};
</script>

<style scope>
.progressurl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 8px;
  overflow: hidden;
  line-height: 0;
  font-size: 12px;
  background-color: #eff0f3;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.weekly {
  color: #64748b;
  font-weight: 400;
  font-size: 1.33em;
}
.bordercard {
  box-shadow: 0px 20px 56px rgba(0, 0, 0, 0.29);
  border-radius: 8px;
}
.tital {
  color: #263238;
  font-weight: normal;
  font-size: 36px;
}
</style>