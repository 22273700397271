<template>
  <div class="row m-0 p-0">
    <div class="col-12">
      <div class="col-12">
        <div class="col-12 tital">Histogram Produced</div>
        <div class="col-12 subtital">Statistics subinformation {{Histogram.length}}</div>
      </div>
      <div class="col-12 my-2 boxchart">
        <!-- <canvas
          id="barChartTransparent"
          style="display: block; width: 452px; height: 213px !important"
          class="chartjs-render-monitor"
        ></canvas> -->
        <ChartJs
          type="bar"
          :data="ChartData"
          :option="ChartOptions"
          :key="ChartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartJs from "@/components/partial/ChartJs.vue";
export default {
  name: "ShortUrlChart",
  props: ["Histogram", "label", "max"],
  components: {
    ChartJs,
  },

  data() {
    return {
      ChartData: {
        labels: this.label,
        datasets: [
          {
            data: this.Histogram,
            backgroundColor: [
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "#DCDCDC",
              "rgba(28,225,172, .5)",
            ],
            label: "Total",
          },
          // {
          //   data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
          //   backgroundColor: "rgba(28,225,172, .5)",
          //   label: "Lose",
          // },
        ],
      },
      ChartOptions: {
        // -------------------------------

        hover: {
          animationDuration: 0,
        },
        animation: {
          duration: 1,
          // onComplete: function () {
          //   var chartInstance = this.chart,
          //     ctx = chartInstance.ctx;

          //   ctx.font = Chart.helpers.fontString(
          //     Chart.defaults.global.defaultFontSize,
          //     Chart.defaults.global.defaultFontStyle,
          //     Chart.defaults.global.defaultFontFamily
          //   );
          //   ctx.textAlign = "bottom";
          //   ctx.textBaseline = "bottom";

          //   this.data.datasets.forEach(function (dataset, i) {
          //     var meta = chartInstance.controller.getDatasetMeta(i);
          //     meta.data.forEach(function (bar, index) {
          //       var data = dataset.data[index];
          //       ctx.fillText(data, bar._model.x, bar._model.y);
          //     });
          //   });
          // },
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                let data = dataset.data[index];
                // let getwaranty =
                //   warranty >= 1000000
                //     ? (warranty / 1000000).toFixed(3) + " GWh"
                //     : warranty >= 1000
                //     ? (warranty / 1000).toFixed(3) + " MWh"
                //     : warranty.toFixed(3) + " kWh";
                // var data = getwaranty;
                ctx.fillText(data, bar._model.x, bar._model.y - 0);
              });
            });
          },
        },
        // -------------------------------
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
          align: "start",
          labels: {
            boxWidth: 6,
            display: true,
            usePointStyle: true,
          },
        },
        layout: {
          //  padding: 20
          // padding: {
          //   left: "0",
          //   right: 0,
          //   top: 0,
          //   bottom: "0",
          // },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                // color: "#e5e9f2",
                display: false,
              },
              ticks: {
                min: 0,
                max: this.max,
                // suggestedMax: 100,
                // max:1000,
                // beginAtZero: true,
                fontSize: 0,
                fontColor: "#182b49",

                // stepSize: 20,
                // callback(value, index, values) {
                //   return `${value}k`;
                // },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },

              barPercentage: 0.6,
              ticks: {
                beginAtZero: true,
                fontSize: 0,
                min: 0,
                max: 50,
                fontColor: "#182b49",
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    // this.chartinit();

    this.chartjsBarChartTransparent();
  },
  methods: {
    chartjsBarChartTransparent(
      selector,
      dataCIn,
      DataCOut,
      label = "Bar chart Transparent"
    ) {
      var ctx = document.getElementById("barChartTransparent");
      if (ctx) {
        ctx.getContext("2d");
        ctx.height = 200;
        var chart = new Chart(ctx, {
          type: "bar",

          data: {
            labels: [
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
              20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
              36, 37, 38, 39, 40,
            ],
            datasets: [
              {
                data: this.Histogram,
                backgroundColor: [
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "#DCDCDC",
                  "rgba(28,225,172, .5)",
                ],
                label: "Profit",
              },
              // {
              //   data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
              //   backgroundColor: "rgba(28,225,172, .5)",
              //   label: "Lose",
              // },
            ],
          },

          options: {
            // -------------------------------
            hover: {
              animationDuration: 0,
            },
            animation: {
              duration: 1,
              onComplete: function () {
                var chartInstance = this.chart,
                  ctx = chartInstance.ctx;

                ctx.font = Chart.helpers.fontString(
                  Chart.defaults.global.defaultFontSize,
                  Chart.defaults.global.defaultFontStyle,
                  Chart.defaults.global.defaultFontFamily
                );
                ctx.textAlign = "bottom";
                ctx.textBaseline = "bottom";

                this.data.datasets.forEach(function (dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y);
                  });
                });
              },
            },
            // -------------------------------
            maintainAspectRatio: true,
            responsive: true,
            legend: {
              display: false,
              position: "bottom",
              align: "start",
              labels: {
                boxWidth: 6,
                display: true,
                usePointStyle: true,
              },
            },
            layout: {
              padding: {
                left: "0",
                right: 0,
                top: 0,
                bottom: "0",
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    // color: "#e5e9f2",
                    display: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    fontSize: 0,
                    fontColor: "#182b49",
                    max: 80,
                    stepSize: 20,
                    callback(value, index, values) {
                      return `${value}k`;
                    },
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  barPercentage: 0.6,
                  ticks: {
                    beginAtZero: true,
                    fontSize: 0,
                    fontColor: "#182b49",
                  },
                },
              ],
            },
          },
        });
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
.boxchart {
  height: 213px !important;
}
.tital {
  color: #263238;
  font-weight: 400;
  font-size: 1em;
}
.subtital {
  color: #607d8b;
  font-weight: 400;
  font-size: 0.875em;
}
</style>