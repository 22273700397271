<template>
  <div class="row mx-0 my-2 p-0 bg ">
    <div class="col-12 m-0 px-5  d-flex justify-content-center ">
      <button type="button" class="btn button px-5  ">{{ tital }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Button1",
  props: ["tital"],
};
</script>

<style scoped>
.button {
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 600;
}
.bg {
  background: #64748b;
  border-radius: 48px;
}
</style>